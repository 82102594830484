import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Container,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Dialog,
  IconButton,
} from "@material-ui/core";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';

import moment from "moment";
import Axios from "axios";
import baseUrl from "../../Utils/baseUrl";

import Carousel from "./Components/Carousel";
import Card from "./Components/Cards";
import NewsCard from "./Components/NewsCards";
import FundRaising from "./Components/FundRaising";
import TestimonialSlider from "./Components/TestimonialSlider";
import DialogContainer from "./Components/Dialog";
import landingPageStyleSheet from "./Landing.module.css";
import "./Landingpage.css";

import CloseIcon from "@material-ui/icons/Close";

import one from "../../Assets/Images/1.jpg";
import two from "../../Assets/Images/2.jpg";
import three from "../../Assets/Images/3.jpg";
import four from "../../Assets/Images/4.jpg";
import five from "../../Assets/Images/5.jpg";
import six from "../../Assets/Images/6.jpg";
import about from "../../Assets/Images/about_v1.jpg";
import avatar from "../../Assets/Images/avatar.svg";
import volunteerBanner from "../../Assets/Images/cta_bg_2.jpg";
import cleanWater from "../../Assets/Images/blog-single-3.jpg";
import edhiLogo from "../../Assets/Icons/EdhiLogo.png";
import video from "../../Assets/IMG_89742.mp4";
import WelcomeModal from "./Components/WelcomeModal";
// import edhiHunger from '../../Assets/Images/edhi-hunger.jpg';
// import educationService from '../../Assets/Images/service-bg.jpg';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "'vidaloka', serif",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      marginBottom: 50,
      fontSize: 40,
    },
    marginBottom: 10,
    fontSize: 30,
  },
  heading2: {
    fontFamily: '"IBM Plex Sans", sans serif',
    fontSize: 20,
    margin: "5px 0px 10px",
    fontWeight: "bold",
  },
  causesSection: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  paperHeading: {
    fontFamily: '"Vidaloka", serif',
    fontSize: 25,
    color: "#191919",
    [theme.breakpoints.up("md")]: {
      fontSize: 48,
    },
    margin: "5px 0px !important",
    fontWeight: "500",
  },
  heading2Paper: {
    fontSize: 20,
    color: "#fd5a5a",
    fontFamily: '"Vidaloka", serif',
    [theme.breakpoints.up("md")]: {
      fontSize: 28,
    },
  },
  list: {
    fontFamily: '"IBM Plex Sans", sans serif',
    lineHeight: "1.7",
    fontSize: 16,
    color: "#646464",
    listStyle: "none",
  },
  text: {
    fontFamily: '"IBM Plex Sans", sans serif',
    lineHeight: "1.7",
    fontSize: 16,
    color: "#646464",
    whiteSpace: "pre-line",
    overflowWrap: "anywhere",
  },
  link: {
    "&:hover": {
      color: "#fd5a5a",
      cursor: "pointer",
    },
  },
  btn: {
    backgroundColor: "#09926e",
    color: "#fff",
    border: "2px solid transparent",
    boxShadow: "none",
    borderRadius: 0,
    padding: "14px 22px",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#161616",
      border: "2px solid #161616",
    },
  },
  topEventCard: {
    backgroundColor: "#f6f6f6",
    padding: "17px 15px",
    position: "relative",
    // marginBottom: 20,
    // marginLeft: 20,
    // marginTop: 20,
    margin: "40px 0px 20px 30px",
  },
  eventCard: {
    backgroundColor: "#f6f6f6",
    padding: "17px 15px",
    position: "relative",
    marginBottom: 20,
  },
  eventTime: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: "500",
    display: "block",
    marginBottom: 5,
  },
  calender: {
    backgroundColor: "#fd5a5a",
    color: "#fff",
    position: "absolute",
    top: 0,
    right: 0,
    width: 55,
    height: 60,
    borderRadius: 3,
    textAlign: "center",
    fontSize: 27,
    fontFamily: "'Vidaloka', serif",
    // lineHeight: 25,
    paddingTop: 7,
  },
  eventTitle: {
    fontSize: 27,
    // cursor: "pointer",
    // "&:hover": {
    // 	color: "#fd5a5a",
    // },
  },
  img: {
    height: "auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: 345,
    },
  },
  imgNewsContain: {
    height: "300px",
    width: "100%",
    margin: "auto",
  },
  imgNews: {
    height: "100%",
    width: "100%",
    borderRadius: "5px 5px 0 0",
  },
  donateBtn2: {
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 10,
    padding: "10px 20px",
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
}));

const tempState = {
  ourCauses: [
    {
      title: "COVID-19 Appeal",
      content:
        "Edhi International Foundation Australia (EIFA) is pledging to provide support...",
      image: one,
    },
    {
      title: "Ambulance Fund",
      content: "The largest private ambulance service in the world set...",
      image: two,
    },
    {
      title: "Fight against hunger",
      content:
        "Giving opportunity to Pakistan’s underprivileged children through the right",
      image: three,
    },
    {
      title: "Patient Care",
      content:
        " Saving lives and providing critical medical aid to Pakistan’s...",
      image: four,
    },
    {
      title: "Home and Orphanage",
      content:
        " Giving assistance to men without work, prospect or hope The...",
      image: five,
    },
    {
      title: "Rehabilitation Centers",
      content:
        " Giving assistance to men without work, prospect or hope The...",
      image: six,
    },
  ],
  events: [
    {
      title: "Clean Water for Childreen",
      color: "#fd5a5a",
      content:
        "Hales of firmament beginning seas yielding won void in land Fowl midst had said made of male evening",
      time: "10AM TO 2PM",
      day: "14",
      month: "Oct",
    },
    {
      title: "Education For Every Child",
      color: "#0FC392",
      content:
        "Hales of firmament beginning seas yielding won void in land Fowl midst had said made of male evening",
      time: "10AM TO 2PM",
      day: "14",
      month: "Oct",
    },
    {
      title: "Food Consultancy Program",
      color: "#F37A32",
      content:
        "Hales of firmament beginning seas yielding won void in land Fowl midst had said made of male evening",
      time: "10AM TO 2PM",
      day: "14",
      month: "Oct",
    },
  ],
  eventSectionImage: cleanWater,
  testimonitalCards: [
    { image: avatar },
    { image: avatar },
    { image: avatar },
    { image: avatar },
    { image: avatar },
    { image: avatar },
    { image: avatar },
    { image: avatar },
  ],
};

export default function LandingPage(props) {
  const { history } = props;
  const classes = useStyles();

  // const theme = useTheme();
  // const { events } = tempState;
  // const largeScreens = useMediaQuery(theme.breakpoints.up('md'));
  // const [showScrollUpButton, setShowScrollUpButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [eventSectionImage, setEventSectionImage] = useState("");
  const [data, setData] = useState({});
  const [newsData, setNewsData] = useState({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    Axios({
      url: `${baseUrl}/home`,
      method: "GET",
    })
      .then((res) => {
        if (res && res.data) {
          // console.log(res.data.data);
          setData(res.data.data);
          setEventSectionImage(res.data.data.upcoming_event[0].image_link);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err)
      });

    // Axios({
    // 	url: `http://api.mediastack.com/v1/news?access_key=7dd6ab85b1bcda408f40bc197b12aa87&limit=9&languages=en&countries=pk,-us&keywords=edhi foundation`,
    // 	method: "GET",
    // })
    // 	.then((res) => {
    // 		if (res && res.data) {
    // 			console.log(res.data.data);
    // 			setNewsData(res.data.data);
    // 			setLoading(false);
    // 		}
    // 	})
    // 	.catch((err) => {
    // 		setLoading(false);
    // 		// console.log(err)
    // 	});
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseOverOnEventsCards = (index) => {
    setEventSectionImage(data.upcoming_event[index].image_link);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: 500,
        }}
      >
        <CircularProgress style={{ color: "#09926E", width: 80, height: 80 }} />
      </div>
    );
  } else {
    return (
      <Fragment>
        <Carousel {...props} data={data.home_slider} />
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
          crossorigin="anonymous"
        />
        <div
          className="get-app-bar-fixed"
          style={{
            boxShadow: "0 1px 2px lightgrey",
            backgroundColor: "#f9f9f9",
            // position: "fixed",
            fontSize: "large",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9998,
            opacity: 1,
            display: "block",
          }}
        >
          <div
            className="get-app-bar-fixed-wrap"
            style={{
              minHeight: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              fontSize: "smaller",
              boxSizing: "border-box",
            }}
          >
            <span
              style={{
                padding: "2px",
                backgroundColor: "white",
                borderRadius: "5px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              <img
                style={{
                  width: "48px",
                  padding: "2px",
                }}
                src={edhiLogo}
              />
            </span>
            <br />
            <p
              style={{
                marginTop: "unset",
                marginBottom: "unset",
                lineHeight: "1em",
              }}
            >
              <strong>EDHI</strong>
              <br />
              <small>
                <i>Australia</i>
              </small>
              <br />
              <small>Services to Humanity</small>
            </p>
            <br />
            <div
              className="get-app-bar-button button-apple"
              style={{
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              <a
                href="https://apps.apple.com/ca/app/edhi-australia/id1506254463"
                target="_blank"
                style={{
                  backgroundColor: "#000000",
                  fontFamily: "inherit",
                  fontSize: "smaller",
                  color: "#ffffff",
                  borderRadius: "4px",
                  textDecoration: "none",
                  display: "inline-block",
                  verticalAlign: "top",
                  textAlign: "center",
                  lineHeight: 1.2,
                  fontWeight: 400,
                  padding: "5px 20px",
                  marginLeft: "20px",
                  marginBottom: "3px",
                  whiteSpace: "nowrap",
                  transition: "all 0.3s",
                }}
              >
                {" "}
                <i class="fab fa-apple"></i> Get it on <br /> App Store!{" "}
              </a>
            </div>
            <br />
            <div
              className="get-app-bar-button button-android"
              style={{
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.donation.edhi.edhidonation"
                target="_blank"
                style={{
                  backgroundColor: "#000000",
                  fontFamily: "inherit",
                  fontSize: "smaller",
                  color: "#ffffff",
                  borderRadius: "4px",
                  textDecoration: "none",
                  display: "inline-block",
                  verticalAlign: "top",
                  textAlign: "center",
                  lineHeight: 1.2,
                  fontWeight: 400,
                  padding: "5px 20px",
                  marginLeft: "20px",
                  marginBottom: "3px",
                  whiteSpace: "nowrap",
                  transition: "all 0.3s",
                }}
              >
                {" "}
                <i
                  class="fab fa-google-play"
                  style={{ color: "greenyellow" }}
                ></i>{" "}
                Get it on <br /> Google Play!{" "}
              </a>
            </div>
          </div>
        </div>
        <Container maxWidth="sm">
          <Grid container spacing={3}>
            {/* <Grid item sm={12} md={6}>
                            <div className={landingPageStyleSheet.paper1} style={{ marginLeft: 20 }}>
                                <h1 className={classes.paperHeading}> WE NEED DONATIONS{' '}</h1>
                                <h2 className={classes.heading2Paper}>AMOUNT TO DONATE</h2>
                                <FormControl variant="outlined" size="small" >
                                    <OutlinedInput
                                        style={{ height: 44 }}
                                        placeholder="125.00"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                AUD
                                        </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <Button variant="contained" onClick={() => { props.history.push("/donation") }} className={classes.donateBtn2}>Donate Now</Button>
                            </div>
                        </Grid> */}
            {/* <Grid item md={1}></Grid> */}
            <Grid item sm={12} md={12}>
              {data &&
                data?.latest_event &&
                data?.latest_event.map((event, index) => {
                  let date = moment(event.event_date, "YYYY-MM-DD");
                  return (
                    <Fragment>
                      <div key={index} className={classes.topEventCard}>
                        <span className={classes.eventTime}>
                          TIME: {moment(event.event_date).format("hh:mm:ss A")}
                        </span>
                        <span
                          style={{ backgroundColor: event.color }}
                          className={classes.calender}
                        >
                          {date.format("D")}
                          <span
                            style={{
                              display: "block",
                              fontSize: 14,
                              textTransform: "uppercase",
                            }}
                          >
                            {date.format("MMM")}
                          </span>
                        </span>
                        <h3 className={classes.eventTitle}>{event.title}</h3>
                        <p className={classes.text}>
                          {event.description.substring(0, 60)}...
                          <span
                            onClick={handleClickOpen}
                            style={{
                              fontWeight: "bold",
                              float: "right",
                            }}
                          >
                            <a
                              href="javascript:void"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              read more
                            </a>
                          </span>
                        </p>
                      </div>
                      <Dialog
                        fullWidth
                        maxWidth="sm"
                        onClose={handleClose}
                        open={open}
                      >
                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
															<IconButton className={classes.closeButton} onClick={handleClose}>
																<CloseIcon />
															</IconButton>
														</div> */}
                        <div style={{ padding: 20 }}>
                          <img
                            style={{
                              height: "auto",
                              width: "100%",
                              margin: "0px auto",
                            }}
                            className={classes.image}
                            src={`https://api.edhi.org.au/${event.image_link}`}
                            alt="gallery-view"
                          />
                          <div style={{ padding: 15 }}>
                            <h4 className={classes.eventTitle}>
                              {event.title}
                            </h4>
                          </div>
                          <p className={classes.text}>{event.description}</p>
                        </div>
                      </Dialog>
                    </Fragment>
                  );
                })}
              {/* <div className={classes.topEventCard}>
                                <span className={classes.eventTime}>TIME: 10Am to 2pm</span>
                                <a style={{ backgroundColor: "#fd5a5a" }} className={classes.calender}>
                                    On
                                    <span style={{
                                        display: 'block',
                                        fontSize: 14,
                                        textTransform: 'uppercase',
                                    }}>going</span>
                                </a>
                                <h3 className={classes.eventTitle}>NEW EVENTS COMING SOON</h3>
                                <p className={classes.text}> Hales of firmament beginning seas yielding won void in land Fowl
                                    midst had said made of male evening
                                </p>
                            </div> */}
            </Grid>
          </Grid>
        </Container>

        <div style={{ height: 50 }} />
        <div className="section-title text-center pb-50 ">
          <span className="span-after">
            SERVING HUMANITY WITHOUT ANY DISCRIMINATION
          </span>
          <h2>Fund Raising </h2>
        </div>
        <FundRaising dropdownData={data.category} {...props} />
        <div style={{ backgroundColor: "#f8f7f7", paddingTop: 5 }}>
          <Container maxWidth="lg">
            <div className="section-title text-center pb-50 ">
              <span className="span-after">
                SERVING HUMANITY WITHOUT ANY DISCRIMINATION
              </span>
              <h2>OUR CAUSES </h2>
            </div>
            <div style={{ height: 50 }} />

            <div
              className={
                landingPageStyleSheet.causesCards + " " + classes.causesSection
              }
            >
              <Grid container spacing={3}>
                {data &&
                  data?.causes &&
                  data?.causes?.length > 0 &&
                  data.causes.map((val, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card data={val} />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Container>
        </div>
        <br />
        <br />
        <Container className="help" maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={5}>
              <div className="help-img">
                <img src={about} className="image" alt="about" />
              </div>
            </Grid>
            <Grid item xs={12} sm={5}>
              <div
                style={{
                  padding: "30px 30px 0 34px",
                  backgroundColor: "#f0f8ff",
                }}
              >
                <div
                  style={{
                    padding: "30px 30px 0 34px",
                    backgroundColor: "#faebd7",
                  }}
                >
                  <div className="section-title">
                    <span className="span-border">ABOUT EDHI FOUNDATION</span>
                    <h2>HOW CAN YOU HELP?</h2>
                  </div>
                  <div>
                    <h5 className={classes.heading2}>
                      Reasons have day were meat dominion they're moving setting
                      great very us hath the multiply evening towse
                    </h5>
                    <br />
                    <p className={classes.text}>
                      Edhi Foundation Pakistan is the largest and most organized
                      social welfare organization in Pakistan. Foundation works
                      round the clock, without any discrimination on the basis
                      of colour, race, language, religion or politics. .
                    </p>
                  </div>
                  <DialogContainer>
                    <Button
                      className={classes.btn}
                      style={{ width: "100%", marginTop: 40 }}
                    >
                      Learn More
                    </Button>
                  </DialogContainer>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={1}></Grid>
          </Grid>
        </Container>
        <div style={{ height: 60 }} />
        <Container maxWidth="lg">
          <div className="section-title text-center pb-50 ">
            <span className="span-after">EVENTS</span>
            <h2>OUR CAMPAIGN</h2>
          </div>
          <div style={{ height: 50 }} />

          <div className={landingPageStyleSheet.causesCards}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <img
                    src={`https://api.edhi.org.au/${eventSectionImage}`}
                    className={classes.img}
                    alt="event-pic"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {data &&
                  data?.upcoming_event &&
                  data?.upcoming_event.map((event2, index) => {
                    let date = moment(event2.event_date, "YYYY-MM-DD");
                    return (
                      <Fragment>
                        <div
                          key={index}
                          className={classes.eventCard}
                          onMouseOver={() => {
                            handleMouseOverOnEventsCards(index);
                          }}
                        >
                          <span className={classes.eventTime}>
                            TIME:{" "}
                            {moment(event2.event_date).format("hh:mm:ss A")}
                          </span>
                          <a
                            style={{ backgroundColor: event2.color }}
                            className={classes.calender}
                          >
                            {date.format("D")}
                            <span
                              style={{
                                display: "block",
                                fontSize: 14,
                                textTransform: "uppercase",
                              }}
                            >
                              {date.format("MMM")}
                            </span>
                          </a>
                          <h3 className={classes.eventTitle}>{event2.title}</h3>
                          <p className={classes.text}>{event2.description}</p>
                        </div>
                      </Fragment>
                    );
                  })}
              </Grid>
            </Grid>
          </div>
        </Container>
        <div style={{ height: 60 }} />
        <div style={{ backgroundColor: "#fff", paddingTop: 5 }}>
          <Container maxWidth="lg">
            <div className="section-title text-center pb-50 ">
              <span className="span-after">NEWS</span>
              <h2>LATEST NEWS</h2>
            </div>
            <div style={{ height: 50 }} />

            <div
              className={
                landingPageStyleSheet.causesCards + " " + classes.causesSection
              }
            >
              <Grid container spacing={3}>
                {data.news &&
                  data?.news.map((news, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <NewsCard news={news} />
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          </Container>
        </div>

        <div style={{ backgroundColor: "#f8f7f7", padding: "135px 0px 95px" }}>
          <Container maxWidth="xl">
            <div className="section-title text-center pb-50 ">
              <span className="span-after">OUR TESTIMONIALS</span>
              <h2>WHAT Our Doners Say</h2>
            </div>
            <div style={{ height: 50 }} />

            <div className={landingPageStyleSheet.causesCards}>
              <TestimonialSlider data={data.testimonials} />
              {/* <Grid container spacing={1}>
                                {
                                   .map((val, index) => (
                                    <Grid item xs={12} sm={2} md={4} lg={3} key={index}>
                                        <TestimonialCard data={val} />
                                    </Grid>))
                                }
                            </Grid> */}
            </div>
          </Container>
        </div>
        <div
          style={{
            backgroundImage: `url(${volunteerBanner})`,
            backgroundColor: "#fff",
            padding: "190px 0 200px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <Container maxWidth="md">
            <h2
              className={classes.heading}
              style={{ margin: "0px auto 0px", maxWidth: "80%" }}
            >
              We are Really Proud of Our Kind <strong>Volunteers</strong>
            </h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.btn}
                style={{
                  width: 220,
                  marginTop: 40,
                  backgroundColor: "transparent",
                  color: "#000",
                  border: "2px solid black",
                }}
                onClick={() => {
                  history.push("/volunteer");
                }}
              >
                Become a Volunteer
              </Button>
            </div>
          </Container>
        </div>
      </Fragment>
    );
  }
}
