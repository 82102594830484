import React, { Fragment, useEffect, useState } from "react";
import {
	Container,
	makeStyles,
	Grid,
	CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import baseUrl from "../../Utils/baseUrl";
import Card from "./Components/Card";

import Adelaide from "../../Assets/Images/Calendars/2023_Adelaide.jpg";
import Brisbane from "../../Assets/Images/Calendars/2023_Brisbane.jpg";
// import Canberra from "../../Assets/Images/Calendars/Canberra-2022.jpg";
import Darwin from "../../Assets/Images/Calendars/2023_Darwin.jpg";
import Geelong from "../../Assets/Images/Calendars/2023_Geelong.jpg";
import Melbourne from "../../Assets/Images/Calendars/2023_Melbourne.jpg";
import NewCastle from "../../Assets/Images/Calendars/2023_New Castle.jpg";
import Perth from "../../Assets/Images/Calendars/2023_Perth.jpg";
import Sydney from "../../Assets/Images/Calendars/2023_Sydney.jpg";
// import Wollongong from "../../Assets/Images/Calendars/Wollongong-2022.jpg";
import Tasmania from "../../Assets/Images/Calendars/2023_Tasmania.jpg";

const useStles = makeStyles((theme) => ({
	heading: {
		fontFamily: "'vidaloka', serif",
		textAlign: "center",
		marginBottom: 50,
	},
	cardContainer: {
		padding: 10,
	},
}));

export default function RamadanCalendars() {
	const classes = useStles();
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);

	const adelaide = [];
	adelaide["image_link"] = Adelaide;
	adelaide["title"] = "Adelaide";
	adelaide["description"] = "Download";

	const brisbane = [];
	brisbane["image_link"] = Brisbane;
	brisbane["title"] = "Brisbane";
	brisbane["description"] = "Download";

	// const canberra = [];
	// canberra["image_link"] = Canberra;
	// canberra["title"] = "Canberra";
	// canberra["description"] = "Download";

	const darwin = [];
	darwin["image_link"] = Darwin;
	darwin["title"] = "Darwin";
	darwin["description"] = "Download";

	const geelong = [];
	geelong["image_link"] = Geelong;
	geelong["title"] = "Geelong";
	geelong["description"] = "Download";

	const melbourne = [];
	melbourne["image_link"] = Melbourne;
	melbourne["title"] = "Melbourne";
	melbourne["description"] = "Download";

	const newcastle = [];
	newcastle["image_link"] = NewCastle;
	newcastle["title"] = "NewCastle";
	newcastle["description"] = "Download";

	const perth = [];
	perth["image_link"] = Perth;
	perth["title"] = "Perth";
	perth["description"] = "Download";

	const sydney = [];
	sydney["image_link"] = Sydney;
	sydney["title"] = "Sydney";
	sydney["description"] = "Download";

	const tasmania = [];
	tasmania["image_link"] = Tasmania;
	tasmania["title"] = "Tasmania";
	tasmania["description"] = "Download";

	useEffect(() => {
		setLoading(false);
	}, []);

	if (loading) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					height: 500,
				}}
			>
				<CircularProgress style={{ color: "#09926E", width: 80, height: 80 }} />
			</div>
		);
	} else {
		return (
			<Fragment>
				<h1 className={classes.heading}>Ramadan Calendar 2023 </h1>
				<Container maxWidth="lg">
					<div className={classes.cardContainer}>
						<Grid container spacing={0}>
							<Grid item xs={12} sm={6} md={3} key={1}>
								<Card data={adelaide} />
							</Grid>
							<Grid item xs={12} sm={6} md={3} key={1}>
								<Card data={brisbane} />
							</Grid>
							<Grid item xs={12} sm={6} md={3} key={1}>
								<Card data={darwin} />
							</Grid>
							<Grid item xs={12} sm={6} md={3} key={1}>
								<Card data={geelong} />
							</Grid>
							<Grid item xs={12} sm={6} md={3} key={1}>
								<Card data={melbourne} />
							</Grid>
							<Grid item xs={12} sm={6} md={3} key={1}>
								<Card data={newcastle} />
							</Grid>
							<Grid item xs={12} sm={6} md={3} key={1}>
								<Card data={perth} />
							</Grid>
							<Grid item xs={12} sm={6} md={3} key={1}>
								<Card data={sydney} />
							</Grid>
							<Grid item xs={12} sm={6} md={3} key={1}>
								<Card data={tasmania} />
							</Grid>
						</Grid>
					</div>
				</Container>
			</Fragment>
		);
	}
}
