import React, { Fragment, useState } from 'react';
import { Grid, Button, Avatar, ListItem, ListItemIcon, ListItemText, useTheme, useMediaQuery } from '@material-ui/core';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import classes from '../Landing.module.css';

export default function CustomCarousle(props) {
    const { data } = props;
    const theme = useTheme();
    const largeScreens = useMediaQuery(theme.breakpoints.up('md'));
    const [play, setPlay] = useState(true);

    // console.log(data)

    return (
        <div className={classes.carousel}>
            {
                largeScreens &&

                <div className={classes.banner}>
                    <Carousel 
                    autoPlay={play} 
                    interval={8000}
                    infiniteLoop={play} 
                    showThumbs={false} showIndicators={false} showArrows={false} showThumbs={false} showStatus={false}>
                        {
                            data && data.map((carousel, index) => (
                                <div key={index}>
                                    <img src={carousel.image} style={{ height: 700 }} />
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            }
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <Carousel autoPlay={play} interval={8000} infiniteLoop={play} showThumbs={false} showIndicators={false} showArrows={false} showThumbs={false} showStatus={false}>
                        {
                            data && data.map((carousel, index) => (
                                <div key={index} style={{ padding: 10 }}>
                                    <span style={{ color: '#09926e' }}>{carousel.sub_title}</span>
                                    <h1 className={classes.carouselHeading}>{carousel.title}{' '} </h1>
                                    {/* <p className={classes.carouselContent}> */}
                                        <p className={classes.carouselContent} dangerouslySetInnerHTML={{__html: carousel.description}} />
                                    {/* </p> */}
                                    <div className={classes.carouselActions}>
                                        <Button className={classes.carouselButton} onClick={() => { props.history.push('/donation') }}>Donate Now</Button>
                                        <ListItem disableGutters style={{ width: 200 }}>
                                            <ListItemIcon>
                                                <Avatar style={{ backgroundColor: '#fff' }}> <ArrowRightOutlinedIcon style={{ fontSize: 34, color: '#fd5a5a' }} /></Avatar>
                                            </ListItemIcon>
                                            <ListItemText primary={<a href='https://www.youtube.com/' target="blank" className={classes.vide0Link}>intro video</a>} />
                                        </ListItem>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </Grid>
            </Grid>
        </div >
    )
}