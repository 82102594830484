import React, { Fragment } from "react";
import { makeStyles, Button, Dialog, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

const useStles = makeStyles((theme) => ({
	card: {
		border: "1px solid rgba(167, 167, 167, 0.5)",
		color: "#444",
		textAlign: "center",
		padding: "10px 10px 0px 10px",
		minHeight: 350,
		// margin: 0.5
		"&:hover": {
			border: "1px solid #161616",
		},
	},
	image: {
		display: "block",
		width: "100%",
		height: "auto",
	},
	title: {
		fontFamily: '"IBM Plex Sans", sans serif',
		fontSize: 22,
		fontWeight: 500,
		color: "#646464",
		margin: 0,
		textAlign: "center",
	},
	text: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 16,
		color: "#646464",
	},
	button: {
		backgroundColor: "#fd5a5a !important",
		color: "#fff !important",
		border: "2px solid transparent !important",
		boxShadow: "none",
		borderRadius: "0px !important",
		padding: "10px 20px !important",
		"&:hover": {
			backgroundColor: "transparent !important",
			color: "#161616 !important",
			border: "2px solid #161616 !important",
		},
	},
}));

export default function GalleryCard(props) {
	const classes = useStles();
	const { data } = props;

	return (
		<Fragment>
			<div className={classes.card}>
				<div style={{ padding: 15 }}>
					<h4 className={classes.title}>{data.title.toUpperCase()}</h4>
				</div>
				<img
					className={classes.image}
					style={{ height: 279 }}
					src={data.image_link}
					alt={data.description}
				/>
				<p
					className={classes.text}
					style={{ textAlign: "center", padding: 10 }}
				>
					<a
						href={data.image_link}
						style={{ textDecoration: "none" }}
						download={data.title + " Calendar 2023"}
					>
						<Button className={classes.button}>{data.description}</Button>
					</a>
				</p>
			</div>
		</Fragment>
	);
}
