import React from 'react';
import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";

// Screens
import LandingPage from '../Screens/Landing/Landing';
import ChairmanMessage from '../Screens/ChairmanMessage/ChaimanMessage';
import EdhiAwards from '../Screens/EdhiAwards/EdhiAwards';
import Vision from '../Screens/Vision/Vision';
import AmbulanceFunds from '../Screens/AmbulanceFunds/AmbulanceFunds';
import FightAgaintstHunger from '../Screens/FightAgainstHunger/FightAgainstHunger';
import HomeAndOrphanage from '../Screens/HomeAndOrphanage/HomeAndOrphanage';
import PatientCare from '../Screens/PatientCare/PatientCare';
import RehabilitationCenter from '../Screens/RehabilitationCenter/RehabilitationCenter';
import FloodRelief from "../Screens/FloodRelief/FloodRelief";
import EdhiWorld from "../Screens/EdhiWorld/EdhiWorld";
import Gallery from "../Screens/Gallery/Gallery";
import RamadanCalendars from "../Screens/RamadanCalendars/RamadanCalendars";
import Contact from "../Screens/Contact/Contact";
import Donation from "../Screens/Donation/Donation";
import Voulnteer from "../Screens/Volunteer/Volunteer";
import Events from "../Screens/Events/Events";
import Register from "../Screens/Register/Register";
import Login from "../Screens/Login/Login";
import ResetPassword from "../Screens/ResetPassword/ResetPassword";
import Profile from "../Screens/Profile/Profile";
import EditProfile from "../Screens/EditProfile/EditProfile";
import ChangePassword from "../Screens/ChangePassword/ChangePassword";
import DonationHistory from "../Screens/DonationHistory/DonationHistory";

const routes = (props) => {
	return (
		<Switch>
			<Route exact path="/" render={(props) => <LandingPage {...props} />} />
			<Route
				exact
				path="/chairman-message"
				render={(props) => <ChairmanMessage {...props} />}
			/>
			<Route
				exact
				path="/recognition"
				render={(props) => <EdhiAwards {...props} />}
			/>
			<Route exact path="/vision" render={(props) => <Vision {...props} />} />
			<Route
				exact
				path="/ambulance-fund"
				render={(props) => <AmbulanceFunds {...props} />}
			/>
			<Route
				exact
				path="/fight-against-hunger"
				render={(props) => <FightAgaintstHunger {...props} />}
			/>
			<Route
				exact
				path="/home-and-orphanage"
				render={(props) => <HomeAndOrphanage {...props} />}
			/>
			<Route
				exact
				path="/patient-care"
				render={(props) => <PatientCare {...props} />}
			/>
			<Route
				exact
				path="/rehabilitation-center"
				render={(props) => <RehabilitationCenter {...props} />}
			/>
			<Route
				exact
				path="/flood-relief"
				render={(props) => <FloodRelief {...props} />}
			/>
			<Route
				exact
				path="/edhi-world"
				render={(props) => <EdhiWorld {...props} />}
			/>
			<Route exact path="/events" render={(props) => <Events {...props} />} />
			<Route exact path="/gallery" render={(props) => <Gallery {...props} />} />
			<Route exact path="/contact" render={(props) => <Contact {...props} />} />
			<Route
				exact
				path="/donation"
				render={(props) => <Donation {...props} />}
			/>
			<Route
				exact
				path="/donation/:option"
				render={(props) => <Donation {...props} />}
			/>
			<Route
				exact
				path="/volunteer"
				render={(props) => <Voulnteer {...props} />}
			/>
			<Route
				exact
				path="/register"
				render={(props) => <Register {...props} />}
			/>
			<Route exact path="/login" render={(props) => <Login {...props} />} />
			<Route
				exact
				path="/reset-password"
				render={(props) => <ResetPassword {...props} />}
			/>
			<Route exact path="/profile" render={(props) => <Profile {...props} />} />
			<Route
				exact
				path="/edit-profile"
				render={(props) => <EditProfile {...props} />}
			/>
			<Route
				exact
				path="/change-password"
				render={(props) => <ChangePassword {...props} />}
			/>
			<Route
				exact
				path="/donation-history"
				render={(props) => <DonationHistory {...props} />}
			/>
			<Route
				exact
				path="/ramadan-calendar"
				render={(props) => <RamadanCalendars {...props} />}
			/>
		</Switch>
	);
};

export default withRouter(routes);
